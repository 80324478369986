@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
.app-bar .logo {
  max-height: 24px;
  margin-right: 24px; }

.instructions-container img {
  max-width: 100%; }

.instructions-container .video-element video {
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: -1px; }

.qrcode {
  opacity: 0.85; }

blockquote {
  margin: 24px 0;
  padding: 4px 24px;
  border-left: 5px solid #ffe564;
  background-color: rgba(255, 229, 100, 0.2); }
  blockquote p {
    margin-top: 16px !important; }

blockquote:first-child {
  margin: 0 0 24px 0; }

blockquote:last-child {
  margin: 24px 0 0 0; }

blockquote:only-child {
  margin: 0; }

.react-swipeable-view-container {
  height: 100%; }

.gallery-title {
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 12px; }

.mobile-stepper {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.footer {
  margin-top: 32px; }

.fade-in {
  animation: fadeIn 0.4s linear; }

.screenshot {
  border: 3px solid #e6e6e6;
  border-radius: 4px; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-in.none {
  display: none; }

.divider {
  margin: 24px 0; }

.grid-overflow {
  overflow: hidden; }

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  overflow-y: scroll; }

.no-select {
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */ }
